export const Featured = [
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866238/Webpage/Services-Before-After/twlight/set_1/11_ie9yku.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866235/Webpage/Services-Before-After/twlight/set_1/1_zyrs36.jpg",
    title: "dssdfssf",
    category: "twilight",
  },
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866254/Webpage/Services-Before-After/twlight/set_2/22_wmw2aj.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866251/Webpage/Services-Before-After/twlight/set_2/2_qeneye.jpg",
    title: "dssdfssf",
    category: "twilight",
  },
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866275/Webpage/Services-Before-After/twlight/set_3/33_qwduky.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866270/Webpage/Services-Before-After/twlight/set_3/3_ra3jgo.jpg",
    title: "dssdfssf",
    category: "twilight",
  },
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866296/Webpage/Services-Before-After/twlight/set_4/54_y91xq8.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866291/Webpage/Services-Before-After/twlight/set_4/5_o1sjae.jpg",
    title: "dssdfssf",
    category: "twilight",
  },

  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866149/Webpage/Services-Before-After/Staging/set_1/1645504632970_lzqhv3.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866145/Webpage/Services-Before-After/Staging/set_1/1645504632967_soskkh.jpg",
    title: "one",
    category: "staging",
  },
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866166/Webpage/Services-Before-After/Staging/set_2/1645504632974_kewth4.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866168/Webpage/Services-Before-After/Staging/set_2/1645504632979_zihoi8.jpg",
    title: "two",
    category: "staging",
  },
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1659273534/Webpage/Services-Before-After/Deculter/set_1/before__x7yhav.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1659273533/Webpage/Services-Before-After/Deculter/set_1/after__xyygf7.jpg",
    title: "two",
    category: "declutter",
  },
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1659273534/Webpage/Services-Before-After/Deculter/set_1/before_rxo4mi.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1659273534/Webpage/Services-Before-After/Deculter/set_1/after_f8uepc.jpg",
    title: "two",
    category: "declutter",
  },
  {
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1659250451/Webpage/Services-Before-After/360%20Panorama/set_1/R0020307-Edit_pano_fz97gv.jpg",
    title: "two",
    category: "360-panaroma",
  },
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1659249867/Webpage/Services-Before-After/Day%20to%20dusk/set_1/22_muorvl_vikjh1.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1659249863/Webpage/Services-Before-After/Day%20to%20dusk/set_1/2_e2cyug_fuvirh.jpg",
    title: "two",
    category: "day-to-dusk",
  },
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1659250807/Webpage/Services-Before-After/HDR%20Blending/set_1/JSH_9937-_xeltxx_npdzoy.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1659250813/Webpage/Services-Before-After/HDR%20Blending/set_1/JSH_9937_ogxd90_py6cqh.jpg",
    title: "two",
    category: "hdr-blending",
  },
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1659251167/Webpage/Services-Before-After/Flash%20Ambient/set_1/_DKF0567_fywvt3.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1659251070/Webpage/Services-Before-After/Flash%20Ambient/set_1/before_y24gpg.jpg",
    title: "two",
    category: "flash-ambient",
  },
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655549606/Webpage/Services-Before-After/Grass/set_1/1_xjtofl.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655549582/Webpage/Services-Before-After/Grass/set_1/1-1_as9ejq.jpg",
    title: "two",
    category: "lawn-replacement",
  },
  {
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655549727/Webpage/Services-Before-After/Grass/set_2/2-2_wyqq2o.jpg",
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655549678/Webpage/Services-Before-After/Grass/set_2/2_vpazfa.jpg",
    title: "two",
    category: "lawn-replacement",
  },
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655549821/Webpage/Services-Before-After/Grass/set_3/3_ctimfu.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655549800/Webpage/Services-Before-After/Grass/set_3/3-3_gipv0u.jpg",
    title: "two",
    category: "lawn-replacement",
  },
  {
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655549906/Webpage/Services-Before-After/Grass/set_4/4-4_jq82rw.jpg",
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655549856/Webpage/Services-Before-After/Grass/set_4/4_sfflgz.jpg",
    title: "two",
    category: "lawn-replacement",
  },
  {
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655865988/Webpage/Services-Before-After/Grass/set_5/_1WM8381_mimumx.jpg",
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655865985/Webpage/Services-Before-After/Grass/set_5/_1WM8321_ld7bac.jpg",
    title: "two",
    category: "lawn-replacement",
  },
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655550306/Webpage/Services-Before-After/Sky/set_1/1-1_lzh0yf.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655550158/Webpage/Services-Before-After/Sky/set_1/1_xavqsz.jpg",
    title: "two",
    category: "sky-replacement",
  },
  {
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655550457/Webpage/Services-Before-After/Sky/set_2/2_xrylcw.jpg",
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655550436/Webpage/Services-Before-After/Sky/set_2/2-2_z0vteb.jpg",
    title: "two",
    category: "sky-replacement",
  },
  {
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655550536/Webpage/Services-Before-After/Sky/set_3/3_y2eiox.jpg",
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655550513/Webpage/Services-Before-After/Sky/set_3/3-3_abvr1y.jpg",
    title: "two",
    category: "sky-replacement",
  },
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655550608/Webpage/Services-Before-After/Sky/set_4/4-4_khjity.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655550580/Webpage/Services-Before-After/Sky/set_4/4_kc3g2i.jpg",
    title: "two",
    category: "sky-replacement",
  },
  {
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866078/Webpage/Services-Before-After/Sky/set_5/DJI_01783_uh6ihg.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866076/Webpage/Services-Before-After/Sky/set_5/DJI_0178_vlqggo.jpg",
    title: "two",
    category: "sky-replacement",
  },
];
