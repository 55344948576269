import { Avatar } from "@mui/material";
import React, { useState } from "react";
import "./components.css";

//Bootstrap Components
import { Container, Navbar } from "react-bootstrap";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import NavbarToggle from "react-bootstrap/esm/NavbarToggle";

//Router
import { Link, useLocation } from "react-router-dom";
import { NavItem } from "reactstrap";

//Static Data
import { Menu } from "../data/menu";

const AppBarComponent = () => {
  const [state, setState] = useState(false);
  const location = useLocation();
  const toggle = () => setState(!state);

  return (
    <Navbar
      id="navbar"
      expand="lg"
      expanded={state}
      variant="light"
      className={`${!state ? "bg-transparent" : "bg-light"}`}
      style={{ position: "absolute", zIndex: 100, width: "100vw" }}
    >
      <Container fluid className="row">
        <Link
          to="/"
          className="navbar-brand col"
          onClick={() => setState(false)}
        >
          <img
            src="https://res.cloudinary.com/picxqinfotech/image/upload/v1655864562/Webpage/Logo/2_uczst7.png"
            alt="picxq"
            className="brand-logo my-0 my-md-2"
          />
        </Link>

        <NavbarToggle
          aria-controls="navbar-hook"
          className="col-auto p-0"
          onClick={() => setState(!state)}
        />
        <NavbarCollapse
          id="navbar-hook"
          className="col-auto justify-content-end"
        >
          <nav className="nav navbar-nav">
            {location.pathname.search("home") < 0 && (
              <NavItem className="menu-item">
                <Link
                  className="nav-link text-muted"
                  onClick={() => setState(false)}
                  to="/home"
                >
                  HOME
                </Link>
              </NavItem>
            )}
            {Menu?.map((r, i) => (
              <NavItem key={i} id="menu-item">
                <Link
                  className="nav-link text-muted"
                  to={r.route}
                  style={{ position: "relative" }}
                >
                  {r.name}
                  {r.type === "dropdown" && (
                    <div
                      id="services"
                      className="shadow"
                      style={{ marginLeft: "-1rem" }}
                    >
                      {r.dp_items.map((p, idx) => (
                        <div
                          key={idx}
                          className=""
                          style={{ minWidth: "200px", width: "100%" }}
                        >
                          <Link
                            className="nav-link text-muted d-flex"
                            to={p.url}
                            onClick={() => setState(false)}
                          >
                            <Avatar
                              src={p.icon}
                              alt={p.item}
                              variant="square"
                              sx={{
                                width: 22,
                                height: 22,
                                margin: "auto 0",
                                marginRight: 1,
                              }}
                            />
                            <span>{p.item}</span>
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}
                </Link>
              </NavItem>
            ))}
          </nav>
        </NavbarCollapse>
      </Container>
    </Navbar>
  );
};

export default AppBarComponent;
