import React, { useEffect } from "react";
import About from "../components/About";
import { useTitle } from "../components/customHooks";
import FeaturesServices from "../components/FeaturesServices";
import Feedback from "../components/Feedback";
import Footer from "../components/Footer";
import Intro from "../components/Intro";
import NavBar from "../components/NavBar";
import Portfolio from "../components/Portfolio";
import Works from "../components/Works";
import Aos from "aos";
import "aos/dist/aos.css";
import AppBarComponent from "../components/AppBarComponent";

const Home = () => {
  useTitle("Home");

  useEffect(() => {
    Aos.init({
      duration: 800,
    });
  });

  return (
    <>
      {/* <NavBar /> */}
      <AppBarComponent />

      {/* Introduction Banner */}
      <Intro />
      <br />
      {/* <Container className="m-auto">
        <ShowCase />
        <Reviews />
      </Container> */}
      {/* <Features /> */}
      {/* <Works /> */}
      {/* <Collaborate /> */}

      {/* Section - 2 About Us */}
      <div data-aos="fade-up">
        <About />
      </div>

      {/* Section 3 - Features & Services */}
      <div data-aos="fade-up">
        <FeaturesServices />
      </div>

      {/* Section 4 - Home Before After */}
      <div data-aos="fade-up">
        <Works />
      </div>

      {/* Section 5 - Portfolio */}
      <div data-aos="fade-up">
        <Portfolio />
      </div>

      {/* Section 6 - Our Clients Say */}
      <div data-aos="fade-up">
        <Feedback />
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default Home;
