import React from "react";
import { Container } from "reactstrap";
import "./components.css";
import banner from "../assets/images/home-banner.webp";
import { useNavigate } from "react-router-dom";

const Intro = () => {
  const navigate = useNavigate();
  return (
    <div className="parent">
      <div className="middle"></div>
      <div className="back"></div>
      <div className="front pt-5">
        <Container>
          <div className="row mt-5">
            <div className="col-md-6 col-sm-12 my-auto">
              <p className="header">
                Welcome !
                <br /> Real Estate Photo Editing Team
              </p>
              <br />
              {/* <p className="sub-header">
                Thousands of companies - from startups to publicly traded
                corporations use Phixer’s software and real estate designers to
                create beautiful marketing to sell their properties for more.
              </p> */}
              <br />
              <button
                className="btn-lg btn-custom-primary shadow"
                onClick={() => navigate("/contact")}
              >
                Try for free
              </button>
            </div>
            <div className="col-sm-12 col-md-6">
              <img
                src="https://res.cloudinary.com/picxqinfotech/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency//v1659523286/Webpage/New%20one/web_bannert_gxjuyu.jpg"
                alt="banner"
                className="img-fluid m-auto"
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Intro;
