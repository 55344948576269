export const portfolioList = [
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655552837/Webpage/Portfolio/DSC_9127_agcwen.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655552834/Webpage/Portfolio/DSC_9247_mblcvi.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655552834/Webpage/Portfolio/DSC_9217_qxh1cd.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655552833/Webpage/Portfolio/DSC_9287_zu7lfg.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655548976/Webpage/Portfolio/DSC04691_awaaxj.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655548974/Webpage/Portfolio/DJI_0865_owtuxl.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655548974/Webpage/Portfolio/DSC04701_gbfv6k.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655548973/Webpage/Portfolio/DJI_0850_znzqde.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655548973/Webpage/Portfolio/DSC03510_r5ii7j.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655525178/Webpage/Portfolio/DSC09812_eytzc3.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655525175/Webpage/Portfolio/DSC09807_cd8wuo.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655525172/Webpage/Portfolio/DSC08625_trxfw2.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655525164/Webpage/Portfolio/4L0A8176_lviwwx.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655525161/Webpage/Portfolio/2_gtnwhf.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655525153/Webpage/Portfolio/_1WM9236_rt0vbn.jpg",
    desc: "",
    url: "#",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655525152/Webpage/Portfolio/_1WM9155_covgxy.jpg",
    desc: "",
    url: "#",
  },
];
