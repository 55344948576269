import { Avatar, Tooltip } from "@mui/material";
import React from "react";
import { SocialLinks } from "../data";

const Social = ({ grey = true, footer = false }) => {
  return (
    <div
      className={`d-flex mt-4 ${
        footer ? "justify-content-center" : "justify-content-start"
      }`}
    >
      {SocialLinks.map((r, i) => (
        <Tooltip key={i} title={r.name}>
          <a
            href={
              r.name === "Telephone"
                ? `tel:${r.url}`
                : r.name === "Gmail"
                ? `mailto:${r.url}`
                : r.url
            }
            target="_blank"
          >
            <Avatar
              src={r.icon}
              alt={r.name}
              variant="square"
              className={grey ? "social-icons" : "social-icons-raw"}
              sx={{ width: 20, height: 20, marginRight: 2 }}
            />
          </a>
        </Tooltip>
      ))}
    </div>
  );
};

export default Social;
