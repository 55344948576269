import experience from "../assets/images/icons/experience.svg";
import quality from "../assets/images/icons/quality.svg";
import turnaround from "../assets/images/icons/turnaround.svg";
import uploads from "../assets/images/icons/uploads.svg";

export const FeaturesList = [
  {
    icon: experience,
    title: "Highly skilled and experienced photo editors",
    content:
      "We have spent over a decade training photographers and designers specifically for the real estate industry. Leverage our highly specialized editors on your next project.",
  },
  {
    icon: quality,
    title: "Quality assurance guarantees consistency",
    content:
      "Every photo that is edited through Phixer undergoes a meticulous quality assurance process. We have multiple quality gates and measurable KPI’s to deliver consistency and on-time delivery.",
  },
  {
    icon: turnaround,
    title: "Fast turnaround",
    content:
      "Our software allows for ultra-fast turnaround times. Delivery of photos is as fast as 8-hours and revisions are within 1-hour.",
  },
  {
    icon: uploads,
    title: "Ultra-Fast Uploads",
    content:
      "Amazon accelerated servers allow for up to 5X faster uploads and downloads to our software. Save time and frustration utilizing our world- class software.",
  },
];

export const FeaturesAndServices = [
  {
    title: "Day to Dusk",
    desc: (
      <>
        Want the Twilight look without being there for Twilight? Image Edits has
        various styles and price points to convert daytime images into
        twilight/nighttime images to grab the attention of potential buyers for
        the property.
      </>
    ),
    url: "/services/day-to-dusk",
  },
  {
    title: "Flash Ambient",
    desc: (
      <>
        To support the fastest growing photography techniques of ‘Flash’ plus
        ‘Ambient’ lighting – IIPVAPI can bring a natural look to the images. Our
        real estate image editors can seamlessly tackle the issue of flash
      </>
    ),
    url: "/services/flash-ambient",
  },
  {
    title: "HDR Blending",
    desc: (
      <>
        The process of High Dynamic Range Blending or HDR image editing involves
        blending of two or more images that vary in temperature, quality, and
        brightness and creating unimpaired images. This service is very useful
        for Professional Photographers who want to remove the image flaws that
        might occur during the shoot based on the lighting and temparature.
      </>
    ),
    url: "/services/hdr-blending",
  },
  {
    title: "Sky Replacement",
    desc: (
      <>
        We can make your pictures look more attractive and striking. If your
        photographs have dull or washed out skies and dead grasses, we can
        correct them and replace them with a clear and bright sky and grass.
        Outsource sky change and grass replacement services to tecpixel.com real
        estate photo editing services and get access to professional and expert
        sky and grass change services at a cost-effective price.
      </>
    ),
    url: "/services/sky-replacement",
  },
  {
    title: "Lawn Replacement",
    desc: (
      <>
        If you are a real estate seller then which kind of photos would you like
        to show to your client or buyer. Would you like to show ‘without sky or
        grass’ or ‘with sky and grass’? As we believe your answer would be ‘with
        sky and grass.’
      </>
    ),
    url: "/services/lawn-replacement",
  },
  {
    title: "360 Panorama",
    desc: (
      <>
        Dynamics are more powerful to draw a viewer’s attention than the
        statics. Panoramic view of an object encourages a viewer to become live
        with it, taking him or her as an integral part and definitely a
        significant player of the dynamic frame. Sometimes, it can create an
        optical illusion to a viewer in a spatiotemporal dimension. A
        professionally created 360 Degree Panorama Photo Stitching can
        definitely prove “life is greater than art”.
      </>
    ),
    url: "/services/360-panaroma",
  },
  {
    title: "Deculter",
    desc: (
      <>
        Our experienced and professional digital de-cluttering editors can
        remove, general clutter, Rubbish, pool debris, vehicles, remove signs
        posters, change water color, wall pictures and everything you want to
        take away from the image. We have a team of highly accomplished photo
        editors who can handle large range of images effectively, and deliver
        terrific results. The results provided by us are of stunning quality and
        clarity. Some of our major services include
      </>
    ),
    url: "/services/declutter",
  },
  {
    title: "Web Design",
    desc: (
      <>
        Web design encompasses many different skills and disciplines in the
        production and maintenance of websites. The different areas of web
        design include web graphic design; user interface design; authoring,
        including standardised code and proprietary software; user experience
        design and search engine optimization.
      </>
    ),
    url: "#",
  },
];
