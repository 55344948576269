import React from "react";

import Landing from "../pages/Landing";
import Home from "../pages/Home";
import Contact from "../pages/Contact";
import BeforeAfter from "../components/BeforeAfter";
import { beforeAfterData } from "../data";
import Portfolio from "../pages/Portfolio";

export const AppRoutes = [
  {
    path: "/",
    component: <Landing />,
  },
  {
    path: "/home",
    component: <Home />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },
  {
    path: "/company/portfolio",
    component: <Portfolio />,
  },
  {
    path: "/services/lawn-replacement",
    component: (
      <BeforeAfter type="lawn-replacement" data={beforeAfterData[0]} />
    ),
  },
  {
    path: "/services/staging",
    component: <BeforeAfter type="staging" data={beforeAfterData[1]} />,
  },
  {
    path: "/services/day-to-dusk",
    component: <BeforeAfter type="day-to-dusk" data={beforeAfterData[3]} />,
  },
  {
    path: "/services/flash-ambient",
    component: <BeforeAfter type="flash-ambient" data={beforeAfterData[4]} />,
  },
  {
    path: "/services/hdr-blending",
    component: <BeforeAfter type="hdr-blending" data={beforeAfterData[5]} />,
  },
  {
    path: "/services/sky-replacement",
    component: <BeforeAfter type="sky-replacement" data={beforeAfterData[6]} />,
  },
  {
    path: "/services/360-panaroma",
    component: <BeforeAfter type="360-panaroma" data={beforeAfterData[7]} />,
  },
  {
    path: "/services/declutter",
    component: <BeforeAfter type="declutter" data={beforeAfterData[8]} />,
  },
  {
    path: "/services/twilight",
    component: <BeforeAfter type="twilight" data={beforeAfterData[2]} />,
  },
];
