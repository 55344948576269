import React, { useState } from "react";
import { Carousel } from "react-bootstrap";

import "./components.css";

const ImageSlider = ({ data, isLanding = true }) => {
  const [index, setIndex] = useState(0);

  return (
    <>
      {isLanding ? (
        <div style={{ position: "relative" }}>
          <Carousel>
            {data.map((r, i) => (
              <Carousel.Item key={i}>
                <img
                  className="landing-image"
                  src={r.image}
                  alt="First slide"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      ) : (
        data.map((r, i) => (
          <div
            key={i}
            className="card shadow h-100 m-auto border-0"
            style={{ borderRadius: 20, overflow: "hidden" }}
          >
            <div className="card-img-top shadow">
              <img src={r.icon} alt={r.alt} className="img-fluid" />
            </div>
            <div className="card-body px-5">
              <p className="content">{r.content}</p>
              <p className="credits">{r.name + ", " + r.platform}</p>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default ImageSlider;
