import { Avatar, TextareaAutosize, TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";
import "../components/components.css";
import { useTitle } from "../components/customHooks";
import { Container } from "reactstrap";
import Social from "../components/Social";
import AppBarComponent from "../components/AppBarComponent";

const Contact = () => {
  useTitle("contact-us");
  const [state, setState] = useState({});

  const updateState = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    setState();
    console.log(state);
  };

  return (
    <div className="parent">
      <AppBarComponent />
      <div className="middle"></div>
      <div className="back"></div>

      <Container className="pt-5">
        <div className="row mx-auto mt-5 pt-4 my-auto">
          <div className="col-sm-12 col-md-5 mx-auto">
            <div className="card shadow">
              <div className="card-body">
                <h3 className="text-center mb-4 mt-2">Contact Our Team</h3>

                <div className="mb-4">
                  <TextField
                    defaultValue={state?.fullName}
                    className="w-100 my-2"
                    variant="outlined"
                    label="Full Name"
                    name="fullName"
                    onChange={updateState}
                  />
                  <TextField
                    defaultValue={state?.phone}
                    className="w-100 mb-2"
                    variant="outlined"
                    label="Phone Number"
                    name="phone"
                    onChange={updateState}
                  />
                  <TextField
                    defaultValue={state?.email}
                    className="w-100 mb-2"
                    variant="outlined"
                    label="Email"
                    name="email"
                    onChange={updateState}
                  />
                  <TextField
                    defaultValue={state?.message}
                    className="w-100 mb-3"
                    variant="outlined"
                    label="Message"
                    multiline
                    name="message"
                    onChange={updateState}
                  />
                  <button
                    onClick={handleSubmit}
                    className="btn-sm p-2 btn-custom-primary w-auto"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5 col-sm-12 my-5 my-md-auto mx-auto">
            <h2>Visit us @</h2>
            <address className="mb-5 mt-4">
              hello@picxq.com
              <br />
              +918148036321
              <br />
              +918526334245
              <br />
            </address>

            <hr />
            <h2 className="mt-2">Connect with us @</h2>
            <Social />
          </div>
        </div>
      </Container>

      <div className="d-none"></div>
      <div className="contact-us"></div>
    </div>
  );
};

export default Contact;
