import { Container } from "@mui/material";
import React from "react";

const About = () => {
  return (
    <div
      className="py-5 d-flex justify-content-center"
      style={{ background: "#ffe5ec" }}
    >
      <Container>
        <div>
          <p className="title">What Drives Us</p>
          <hr className="w-25 mx-auto" />
          <p
            className="text-justify m-md-5"
            style={{ fontSize: "large", fontWeight: 400, lineHeight: "1.5" }}
          >
            Welcome to Picxq.com. Augment your commercial/residential property
            values and inspire sales with top-notch real estate photo editing
            services from Picxq. We will take care of your property photos in a
            fast and efficient way. Our services are a straight way to
            professional property photography. Picxq offers a wide area of
            services to everyone from real estate agents to developers and
            online retailers who want to showcase their products with
            professional photos. Let us help you create a dream home with the
            help of real estate retouching. We provide you with unrestrained
            access to world-class real estate photo editors, 24-hour operations,
            and the latest image processing techniques within your budget.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default About;
