import React, { useEffect, useState } from "react";
import { useTitle } from "../components/customHooks";
import NavBar from "../components/NavBar";

import "../components/components.css";
import { portfolioList } from "../data";
import Footer from "../components/Footer";
import { Container } from "@mui/material";
import GenericModal from "../components/GenericModal";
import AppBarComponent from "../components/AppBarComponent";

const Portfolio = () => {
  useTitle("portfolio");
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState();

  const toggle = () => setOpen(!open);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="parent">
      {/* <NavBar /> */}
      <AppBarComponent />

      <div className="middle"></div>
      <div className="back"></div>

      <Container className="pt-5">
        <div className="row mt-5 pt-5">
          <div className="col-auto">
            <p
              className="text-custom-primary"
              style={{
                fontSize: "40pt",
                fontWeight: 700,
                mixBlendMode: "multiply",
              }}
            >
              Our Portfolio
            </p>
          </div>
        </div>
      </Container>

      <div className="row my-5 justify-content-center p-0">
        {portfolioList.map((r, i) => (
          <div key={i} className="col-md-3 col-sm-12 shadow p-0 m-1">
            <img
              src={r.image}
              alt={r.desc}
              className="img-fluid w-100"
              style={{ cursor: "pointer" }}
              onClick={() => (toggle(), setImage(r.image))}
            />
          </div>
        ))}
        <GenericModal
          open={open}
          toggle={toggle}
          portfolio={true}
          pimg={image}
        />
      </div>

      <Footer />
    </div>
  );
};

export default Portfolio;
