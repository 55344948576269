export const BeforeAfterHome = [
  {
    name: "grass",
    tone: "#4f772d",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866954/Webpage/Before-After/set_1/1-1_as9ejq_mwoq7p.jpg",

    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866952/Webpage/Before-After/set_1/1_xjtofl_tlwxfu.jpg",
  },
  {
    name: "sky",
    tone: "#4f772d",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866967/Webpage/Before-After/set_2/2_xrylcw_owk87x.jpg",
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866974/Webpage/Before-After/set_2/2-2_z0vteb_xyhcut.jpg",
  },

  {
    name: "furniture",
    tone: "#ef233c",
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866988/Webpage/Before-After/set_3/1645504632974_ehycnv.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655866992/Webpage/Before-After/set_3/1645504632979_tr8awr.jpg",
  },
  {
    name: "twilight",
    tone: "#ef233c",
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655867007/Webpage/Before-After/set_4/22_muorvl.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655867002/Webpage/Before-After/set_4/2_e2cyug.jpg",
  },
  {
    name: "outdoor",
    tone: "#ef233c",
    before:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655867031/Webpage/Before-After/set_5/1645504632970_yazr1p.jpg",
    after:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655867028/Webpage/Before-After/set_5/1645504632967_giu0yt.jpg",
  },
];

export const beforeAfterData = [
  {
    title: "Lawn Replacement",
    desc: (
      <p>
        If you are a real estate seller then which kind of photos would you like
        to show to your client or buyer. Would you like to show ‘without sky or
        grass’ or ‘with sky and grass’? As we believe your answer would be ‘with
        sky and grass.’-
        <br />
        <br /> Sometimes due to bad weather conditions, dull or cloudy sky, lack
        of maintenance, negligence, changing season etc, the grass dies. Hence,
        in that case you may need this service to make your picture presentable.
        <br />
        <br />
        Should You Photoshop The Grass In Real Estate Photo? (this is heading
        big font) There is no black and white answer. It is generally not
        standard practice in real estate photography to modify permanent
        features like power lines, antennas or power poles and physical property
        features. But when it comes to modify impermanent or semi-permanent
        features like landscape likewise grass, bushes etc., there is a variety
        of opinions. Some will argue that grass and bushes are seasonal and it’s
        not a big deal to Photoshop them and some will vehemently take the other
        side of the argument.
        <br />
        <br /> We can make your pictures look more attractive and striking. If
        your photographs have dull or washed out skies and dead grasses, we can
        correct them and replace them with a clear and bright sky and grass.
        Outsource sky change and grass replacement services to tecpixel.com real
        estate photo editing services and get access to professional and expert
        sky and grass change services at a cost-effective price.
      </p>
    ),
    banner: {
      front:
        "https://res.cloudinary.com/picxqinfotech/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency//v1657982972/Webpage/web/grass_gpyeib.jpg",
    },
  },
  {
    title: "Virtual Staging",
    desc: (
      <p>
        Virtual Staging has emerged as a formidable technological advancement in
        Home Staging techniques. It's a cost-effective alternative to
        traditional staging, however, it can also be a great supplement to the
        traditional staging of vacant homes. As the pioneers of the Virtual
        Staging movement, the founders and professionals of Virtually Staging
        Properties℠ have developed an exclusive, proprietary virtual staging
        process. Our process allows us to use current furnishings and decor to
        create virtually staged photos of vacant homes, producing exceptionally
        photorealistic staged photos for clients all around the country. <br />
        <br />
        We photograph the empty property, then share an amazing portfolio of
        rooms and styles. Once you’ve selected your options, our talented group
        of 3D CGI artists use the latest and greatest Hollywood techniques and
        software to magically furnish your space!
      </p>
    ),
    banner: {
      front:
        "https://res.cloudinary.com/picxqinfotech/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency//v1657982971/Webpage/web/staging_thywla.jpg",
    },
  },
  {
    title: "Virtual Twilight",
    desc: (
      <p>
        Virtual Twilights give the illusion of beautiful Twilight photography
        without the hassle of scheduling a separate evening appointment. If the
        weather isn’t cooperating for a Twilight shoot or you only need a few
        photos featuring a stunning sunset in the background, this service is a
        great way to easily transform existing daytime photography into a
        Virtual Twilight.
        <br />
        <br /> The warmth and welcoming appeal of twilight photos really set off
        a glamorous, stylish vibe attracting more views online, and ultimately
        creating an emotional connection for buyers. Check out these
        before/afters and you’ll see just how alluring twilight can truly be!{" "}
        <br />
        <br />
        We photograph the property like we normally would, then our talented
        group of 3D CGI artists use the latest and greatest Hollywood techniques
        and software to magically make your afternoon shot look like dusk!
        <br />
        <br />
        Virtual Twilights is a great alternative to a full twilight shoot. Get
        that one “Magic Hour” photo you need to tell a home’s story.Twilight.
      </p>
    ),
    banner: {
      front:
        "https://res.cloudinary.com/picxqinfotech/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency//v1657982971/Webpage/web/twlight_hgqt7m.jpg",
    },
  },
  {
    title: "Day to Dusk",
    desc: "Want the Twilight look without being there for Twilight?    Image Edits has various styles and price points to convert daytime images into twilight/nighttime images to grab the     attention of potential buyers for the property.",
    banner: {
      front:
        "https://res.cloudinary.com/picxqinfotech/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency//v1657982971/Webpage/web/hdr_zctpum.jpg",
    },
  },
  {
    title: "Flash Ambient",
    desc: "To support the fastest growing photography techniques of ‘Flash’ plus ‘Ambient’ lighting – IIPVAPI can bring a  natural look to the images. Our real estate image editors can seamlessly tackle the issue of flash",
    banner: {
      front:
        "https://res.cloudinary.com/picxqinfotech/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency//v1657982971/Webpage/web/hdr_zctpum.jpg",
    },
  },
  {
    title: "HDR Blending",
    desc: (
      <p>
        The process of High Dynamic Range Blending or HDR image editing involves
        blending of two or more images that vary in temperature, quality, and
        brightness and creating unimpaired images. This service is very useful
        for Professional Photographers who want to remove the image flaws that
        might occur during the shoot based on the lighting and temparature.
        <br />
        <br />
        HDR Blending Service provided by Images retouch Includes the following:
        <br />
        <br />
        <ul>
          <li>Retaining Window details</li>
          <li>Blending Multi Exposure Adjustments</li>
          <li>White Balance Adjustments</li>
          <li>Highlights and Shadows</li>
          <li>Outlook Corrections and Noise Reductions</li>
          <li>Sky replacement or removal flashlights</li>
          <li>unwanted object removal</li>
          <li>Fire in Fire Place</li>
          <li>TV screen replacement, etc</li>
        </ul>
        <br />
        Over the years, Images Retouch has gained the fame of being a trusted
        source for the service. Many of our customers in the field of Real
        Estate Photgraphy & Listing Agents have a long term participation with
        us. To gain your trust, we simply offer a sample (trial) job without
        cost or commitment to give cognizance into our Real Estate HDR Photo
        Editing Services.
      </p>
    ),
    banner: {
      front:
        "https://res.cloudinary.com/picxqinfotech/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency//v1657982971/Webpage/web/hdr_zctpum.jpg",
    },
  },
  {
    title: "Sky Replacement",
    desc: (
      <p>
        If you are a real estate seller then which kind of photos would you like
        to show to your client or buyer. Would you like to show ‘without sky or
        grass’ or ‘with sky and grass’? As we believe your answer would be ‘with
        sky and grass.’-
        <br />
        <br /> Sometimes due to bad weather conditions, dull or cloudy sky, lack
        of maintenance, negligence, changing season etc, the grass dies. Hence,
        in that case you may need this service to make your picture presentable.
        <br />
        <br />
        Should You Photoshop The Grass In Real Estate Photo? (this is heading
        big font) There is no black and white answer. It is generally not
        standard practice in real estate photography to modify permanent
        features like power lines, antennas or power poles and physical property
        features. But when it comes to modify impermanent or semi-permanent
        features like landscape likewise grass, bushes etc., there is a variety
        of opinions. Some will argue that grass and bushes are seasonal and it’s
        not a big deal to Photoshop them and some will vehemently take the other
        side of the argument.
        <br />
        <br /> We can make your pictures look more attractive and striking. If
        your photographs have dull or washed out skies and dead grasses, we can
        correct them and replace them with a clear and bright sky and grass.
        Outsource sky change and grass replacement services to tecpixel.com real
        estate photo editing services and get access to professional and expert
        sky and grass change services at a cost-effective price.
      </p>
    ),
    banner: {
      front:
        "https://res.cloudinary.com/picxqinfotech/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency//v1657982971/Webpage/web/sky_krd80e.jpg",
    },
  },
  {
    title: "360 Panorama",
    desc: (
      <p>
        Dynamics are more powerful to draw a viewer’s attention than the
        statics. Panoramic view of an object encourages a viewer to become live
        with it, taking him or her as an integral part and definitely a
        significant player of the dynamic frame. Sometimes, it can create an
        optical illusion to a viewer in a spatiotemporal dimension. A
        professionally created 360 Degree Panorama Photo Stitching can
        definitely prove “life is greater than art”.
        <br />
        <br /> A 360° panorama creation can eclipse any static artwork by a
        certain richness, liveliness and complexity. It awakens a widely
        different sentiment far-reaching of the monotony created by a piece of
        static art. Therefore, 360 Degree Panorama Photo Stitching is
        extensively used in almost all niche and industries for accentuating a
        product and services for selling and marketing these easily. Panoramic
        views are highly effective for sales promotion, advertising and digital
        marketing.
        <br />
        <br /> Having numerous benefits of 360 Degree Panorama Photo Stitching
        in accentuating a business, its products and services significantly, we,
        at OURS Global Photo Editing Service Company, are dedicated to ensuring
        you all through our professional photo stitching services. Our expert
        solutions on it allow the potential customers to rotate a picture and
        examine it properly after having a quick view of it from the different
        sides.
      </p>
    ),
    banner: {
      front:
        "https://res.cloudinary.com/picxqinfotech/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency//v1657982971/Webpage/web/360_onchcr.jpg",
    },
  },
  {
    title: "Deculter",
    desc: (
      <p>
        Our experienced and professional digital de-cluttering editors can
        remove, general clutter, Rubbish, pool debris, vehicles, remove signs
        posters, change water color, wall pictures and everything you want to
        take away from the image. We have a team of highly accomplished photo
        editors who can handle large range of images effectively, and deliver
        terrific results. The results provided by us are of stunning quality and
        clarity. Some of our major services include
      </p>
    ),
    banner: {
      front:
        "https://res.cloudinary.com/picxqinfotech/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency//v1657982971/Webpage/web/hdr_zctpum.jpg",
    },
  },
];
