import grass from "../assets/icons/grass.ico";
import staging from "../assets/icons/staging.ico";
import twilight from "../assets/icons/twilight.ico";
import portfolio from "../assets/icons/portfolio.ico";
import hdr from "../assets/icons/hdr.ico";
import panorama from "../assets/icons/panorama.ico";
import day from "../assets/icons/day.ico";
import flash from "../assets/icons/flash.ico";
import declutter from "../assets/icons/declutter.ico";
import sky from "../assets/icons/sky.ico";

export const Menu = [
  {
    type: "dropdown",
    name: "COMPANY",
    route: "#",
    dp_items: [
      {
        item: "Portfolio",
        url: "/company/portfolio",
        icon: portfolio,
      },
    ],
  },
  {
    type: "dropdown",
    name: "SERVICES",
    route: "#",
    dp_items: [
      {
        item: "Day to Dusk",
        url: "/services/day-to-dusk",
        icon: day,
      },
      {
        item: "Flash Ambient",
        url: "/services/flash-ambient",
        icon: flash,
      },
      {
        item: "HDR Blending",
        url: "/services/hdr-blending",
        icon: hdr,
      },
      {
        item: "Sky Replacement",
        url: "/services/sky-replacement",
        icon: sky,
      },
      {
        item: "Lawn Replacement",
        url: "/services/lawn-replacement",
        icon: grass,
      },
      {
        item: "360 Panorama",
        url: "/services/360-panaroma",
        icon: panorama,
      },
      {
        item: "Decultter",
        url: "/services/declutter",
        icon: declutter,
      },
      {
        item: "Virtual Staging",
        url: "/services/staging",
        icon: staging,
      },
      {
        item: "Virtual Twilight",
        url: "/services/twilight",
        icon: twilight,
      },
    ],
  },
  {
    name: "CONTACT US",
    route: "/contact",
  },
];
