import React from "react";
import { Carousel } from "react-bootstrap";
import { Container } from "reactstrap";
import { LiveReviews } from "../data";

const Feedback = () => {
  return (
    <div className="feedback py-5">
      <Container>
        <p className="title my-4">Our Clients Say</p>

        <Carousel className="mt-5">
          {LiveReviews.map((r, i) => (
            <Carousel.Item key={i}>
              <p className="feedback-section">{r.content}</p>
              <strong className="float-end">
                ~ <i>{r.name}</i>
              </strong>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default Feedback;
