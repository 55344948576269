import React, { useState } from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import { Modal, ModalBody } from "reactstrap";

const GenericModal = ({
  open,
  toggle,
  toggleBefore,
  bimg,
  aimg,
  portfolio = false,
  pimg,
  type,
}) => {
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      centered
      size={portfolio ? "lg" : "xl"}
      className="shadow"
      style={{ height: "90vh", overflow: "hidden" }}
      onClosed={toggleBefore}
    >
      <ModalBody className="p-0">
        {portfolio ? (
          <img src={pimg} alt="portfolio" className="img-fluid" />
        ) : type === "360-panaroma" ? (
          <img src={aimg} alt="portfolio" className="img-fluid" />
        ) : (
          <ReactCompareSlider
            changePositionOnHover
            itemOne={
              <ReactCompareSliderImage
                src={bimg}
                srcSet={bimg}
                alt="Image one"
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={aimg}
                srcSet={aimg}
                alt="Image two"
              />
            }
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default GenericModal;
