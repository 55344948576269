import React from "react";
import { Container } from "reactstrap";
import { FeaturesAndServices } from "../data";
import { Link, useNavigate } from "react-router-dom";

const FeaturesServices = () => {
  const navigate = useNavigate();

  return (
    <div className="fns py-5">
      <p className="sub-header text-center">Features</p>
      <p className="title">Our Features & Services</p>

      <Container>
        <div className="row justify-content-center">
          {FeaturesAndServices.map((r, i) => (
            <div
              key={i}
              className="col-md-3 col-sm-12 card shadow-sm bg-light m-1 py-2"
              style={{ overflow: "hidden" }}
            >
              <div className="card-header bg-light text-center h5">
                {r.title}
              </div>
              <div className="card-body mb-2" style={{ overflow: "hidden" }}>
                <div style={{ maxHeight: 180, textOverflow: "ellipsis" }}>
                  <p className="card-text">{r.desc} ...</p>
                </div>
              </div>
              <button
                className="btn px-0 text-primary"
                onClick={() => (navigate(r.url), window.scrollTo(0, 0))}
              >
                view more
              </button>
            </div>
          ))}
          {/* {FeaturesAndServices.map((r, i) => (
            <div key={i} className="col-3">
              <div className="card bg-light shadow">
                <div className="card-body text-center">
                  <h5 className="text-center">{r.title}</h5>
                  <p className="text-start my-3">{r.desc}</p>
                  <button className="btn-sm btn-custom-primary px-4">
                    More
                  </button>
                </div>
              </div>
            </div>
          ))} */}
        </div>
      </Container>
    </div>
  );
};

export default FeaturesServices;
