import React from "react";
import { Container } from "reactstrap";
import ImageSlider from "../components/ImageSlider";
import { LandingCarousel } from "../data";
import { Link } from "react-router-dom";
import logo from "../assets/logo-full.png";

import "../components/components.css";
import { useTitle } from "../components/customHooks";

const Landing = () => {
  useTitle("Welcome");

  return (
    <>
      {/* <Title /> */}

      <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
        <img
          className="landing-logo navbar-brand img-fluid m-5"
          src={logo}
          //src="https://res.cloudinary.com/picxqinfotech/image/upload/v1655526966/Webpage/Logo/111Untitled2_1_zjwnwj.png"
          alt="picxq"
        />
        <Container className="mt-md-4">
          <div
            className="ms-auto menu py-3 ps-5 py-md-4 ps-md-5 pe-3 shadow"
            style={{ mixBlendMode: "difference", borderRadius: 10 }}
          >
            <Link
              to="/home"
              className="navbar-brand my-auto menu-hover mx-auto"
            >
              Home
            </Link>
            <span
              className="mx-2 my-auto text-muted"
              style={{ fontWeight: 600, fontSize: 20 }}
            >
              |
            </span>
            <Link
              to="/contact"
              className="mx-auto navbar-brand my-auto menu-hover"
            >
              Contact us
            </Link>
          </div>
        </Container>
      </nav>
      <ImageSlider data={LandingCarousel} />
    </>
  );
};

export default Landing;
