import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AppRoutes } from "./routes";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {AppRoutes.map((r, i) => (
            <Route key={i} path={r.path} element={r.component} />
          ))}
        </Routes>
      </Router>
      <ScrollToTop />
    </div>
  );
}

export default App;
