export const LandingCarousel = [
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655525178/Webpage/Portfolio/DSC09812_eytzc3.jpg",
    title: "One",
    desc: "sub one",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655525175/Webpage/Portfolio/DSC09807_cd8wuo.jpg",
    title: "two",
    desc: "sub two",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655525172/Webpage/Portfolio/DSC08625_trxfw2.jpg",
    title: "three",
    desc: "sub three",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655525164/Webpage/Portfolio/4L0A8176_lviwwx.jpg",
    title: "four",
    desc: "sub four",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655525161/Webpage/Portfolio/2_gtnwhf.jpg",
    title: "five",
    desc: "sub five",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655525153/Webpage/Portfolio/_1WM9236_rt0vbn.jpg",
    title: "six",
    desc: "sub six",
  },
  {
    image:
      "https://res.cloudinary.com/picxqinfotech/image/upload/v1655525152/Webpage/Portfolio/_1WM9155_covgxy.jpg",
    title: "seven",
    desc: "sub seven",
  },
];
