import React, { useState } from "react";
import { Container } from "reactstrap";
import NavBar from "./NavBar";
import Footer from "./Footer";

import "./components.css";
import { Featured } from "../data";
import GenericModal from "./GenericModal";
import { useTitle } from "./customHooks";
import { useLocation } from "react-router-dom";
import AppBarComponent from "./AppBarComponent";

const BeforeAfter = ({ data, type, isPortfolio = false }) => {
  useTitle(type);
  const [open, setOpen] = useState(false);
  const [before, setBefore] = useState(true);
  const [state, setState] = useState({ after: "", before: "" });

  const location = useLocation();

  const toggleBefore = () => setBefore(!before);

  const toggleOpen = (b, a, c) => {
    setOpen(!open);
    setBefore(true);
    setState({ before: b, after: a, category: c });
  };

  return (
    <div className="parent">
      {/* <NavBar /> */}
      <AppBarComponent />
      <div className="middle"></div>
      <div className="back"></div>

      {/* Banner */}
      <Container className="pt-5">
        <div className="row mt-5 mt-md-0">
          <div className="col-md-6 col-sm-12 my-auto">
            <p
              className="text-custom-primary"
              style={{
                fontSize: "40pt",
                fontWeight: 700,
                mixBlendMode: "multiply",
              }}
            >
              {data.title}
            </p>
          </div>
          <div className="col-md-6 col-sm-12" style={{ position: "relative" }}>
            <img className="img-fluid" src={data.banner.front} />
          </div>
        </div>
      </Container>
      <Container className="mb-5">
        <p
          className="text-custom-primary"
          style={{
            fontSize: "15pt",
            fontWeight: 400,
            mixBlendMode: "multiply",
          }}
        >
          {data.desc}
        </p>
      </Container>
      <Container style={{ position: "relative" }}>
        {/* Featured Properties */}

        {/* Featured Items List */}
        <div>
          <h1>Featured Properties</h1>
          <div className="row my-5">
            {Featured.filter(
              (f) => location.pathname.indexOf(f.category) > 0
            ).map((r, i) => (
              <div key={i} className="col-md-3 col-sm-12 featured-item my-2">
                <img
                  src={r.after}
                  alt={r.title}
                  className="h-100"
                  onClick={() => toggleOpen(r.before, r.after, r.category)}
                />

                {/* Fullscreen Before After Comparison */}
                {!isPortfolio && (
                  <GenericModal
                    open={open}
                    toggle={toggleOpen}
                    toggleBefore={toggleBefore}
                    bimg={state.before}
                    aimg={state.after}
                    type={state.category}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default BeforeAfter;
