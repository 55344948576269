export const ReviewsList = [
  {
    value: 5,
    url: "https://www.redfin.com/openbook/home-photography/washington-dc-phixer-sp1595347",
    name: "Redfin",
    count: 634,
  },
  {
    value: 5,
    url: "https://g.page/Phixer?share",
    name: "Google",
    count: 39,
  },
  {
    value: 5,
    url: "https://www.facebook.com/phixerinc/reviews/",
    name: "Facebook",
    count: 43,
  },
];

export const LiveReviews = [
  {
    content:
      "Despite the technology of camera phones, sometimes you just want to add that extra zip to your photos, and this has to be the easiest, most complete available. A process driven editor that brings out rich details you may often overlook a vast collection of intuitive services provide the equivalent power.",
    name: "John Schiavone, Jr.",
  },
  {
    content:
      "I cannot express it more clearly than to say PICXQ is nothing less than INCREDIBLE. They truly deliver top-notch work, always high quality in everything they do and they have impeccable customer service - always accomodating and quick to reply to any questions or needs I have.",
    name: "Anonymous",
  },
];
