import { useEffect } from "react";

export const useTitle = (title) => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = "PICXQ | " + title.replace("-", " ").toUpperCase();
    return () => {
      document.title = prevTitle;
    };
  });
};
