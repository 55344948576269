import {
  Avatar,
  Button,
  ButtonGroup,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import React, { useState } from "react";
import { BeforeAfterHome } from "../data/beforeAfter";
import next from "../assets/icons/next.ico";
import prev from "../assets/icons/prev.ico";
import ba from "../assets/ba.png";

import "./components.css";

const Works = () => {
  const [isBefore, setBefore] = useState(true);
  const [index, setIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const r = BeforeAfterHome;

  const toggle = (e = true) => setBefore(e);

  const handleSlide = (prev = false, i) => {
    if (prev) {
      setIndex(index - 1);
      toggle();
    } else {
      setIndex(index + 1);
      toggle();
    }
  };

  return (
    <div className="my-5">
      <h1 className="header text-center">How it works</h1>
      <br />
      <p className="sub-header text-center">
        Click on the photo below to see how our experts turn <br />
        any photo into a cover shot.
      </p>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ position: "relative" }}
      >
        <img
          alt={r.name}
          src={isBefore ? r[index].before : r[index].after}
          className="content shadow"
        />

        <img
          src={ba}
          className=""
          onClick={() => setBefore(!isBefore)}
          style={{
            position: "absolute",
            objectFit: "contain",
            width: 40,
            borderRadius: 100,
            filter: isBefore ? "brightness(80%)" : "brightness(100%)",
            border: "5px solid white",
            cursor: "pointer",
          }}
        />

        <div className="d-flex justify-content-center w-50 mt-3">
          <div>
            <button
              className="btn"
              onClick={() => handleSlide(true)}
              disabled={index === 0 && true}
            >
              <Avatar src={prev} alt="previous" />
            </button>
            <button
              className="btn"
              onClick={() => handleSlide()}
              disabled={index === r.length - 1 && true}
            >
              <Avatar src={next} alt="next" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
