import fb from "../assets/icons/social/facebook.ico";
import instagram from "../assets/icons/social/instagram.ico";
import call from "../assets/icons/social/call.ico";
import youtube from "../assets/icons/social/youtube.ico";
import linkedin from "../assets/icons/social/linkedin.ico";
import gmail from "../assets/icons/social/gmail.ico";

export const SocialLinks = [
  {
    icon: call,
    url: "+918148036321",
    name: "Telephone",
  },
  {
    icon: gmail,
    url: "hello@picxq.com",
    name: "Gmail",
  },
  {
    icon: instagram,
    url: "https://instagram.com/picxqinfotech?igshid=YmMyMTA2M2Y=",
    name: "Instagram",
  },
  {
    icon: fb,
    url: "https://www.facebook.com/profile.php?id=100078389637330",
    name: "Facebook",
  },
  // {
  //   icon: youtube,
  //   url: "#",
  //   name: "Youtube",
  // },
  {
    icon: linkedin,
    url: "https://www.linkedin.com/in/picx-q-037691235",
    name: "LinkedIn",
  },
];
