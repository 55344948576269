import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import logo from "../assets/logo-full.png";
import Social from "./Social";
import "./components.css";

const Footer = () => {
  const date = new Date();
  const toTop = () => window.scrollTo(0, 0);

  return (
    <div className="footer pt-5 pb-1 px-3 px-md-0">
      <div className="row justify-content-around mb-5 text-center">
        <div className="col-md-auto col-sm-12">
          <h5>Useful Links</h5>
          <hr />
          <div className="mt-3 text-start" style={{ lineHeight: "1.8" }}>
            <Link onClick={toTop} to="/home" className="footer-links">
              Home
            </Link>
            <br />
            <Link onClick={toTop} to="/" className="footer-links">
              Landing Page
            </Link>
            <br />
            <Link
              onClick={toTop}
              to="/company/portfolio"
              className="footer-links"
            >
              Portfolio
            </Link>
            <br />
            <Link onClick={toTop} to="/contact" className="footer-links">
              Contact Us
            </Link>
            <br />
          </div>
        </div>
        <div className="col-md-auto col-sm-12">
          <h5>Our Services</h5>
          <hr />
          <div
            className="text-muted mt-3 text-start"
            style={{ lineHeight: "1.8" }}
          >
            <Link
              onClick={toTop}
              to="/services/day-to-dusk"
              className="footer-links"
            >
              Day to Dusk
            </Link>
            <br />
            <Link
              onClick={toTop}
              to="/services/flash-ambient"
              className="footer-links"
            >
              Flash Ambient
            </Link>
            <br />
            <Link
              onClick={toTop}
              to="/services/hdr-blending"
              className="footer-links"
            >
              HDR Blending
            </Link>
            <br />
            <Link
              onClick={toTop}
              to="/services/sky-replacement"
              className="footer-links"
            >
              Sky Replacement
            </Link>
            <br />
            <Link
              onClick={toTop}
              to="/services/lawn-replacement"
              className="footer-links"
            >
              Lawn Replacement
            </Link>
            <br />
            <Link
              onClick={toTop}
              to="/services/360-panaroma"
              className="footer-links"
            >
              360 Panorama
            </Link>
            <br />
            <Link
              onClick={toTop}
              to="/services/declutter"
              className="footer-links"
            >
              Deculter
            </Link>
            <br />
            <Link
              onClick={toTop}
              to="/services/staging"
              className="footer-links"
            >
              Virtual Staging
            </Link>
            <br />
            <Link
              onClick={toTop}
              to="/services/twilight"
              className="footer-links"
            >
              Virtual Twilight
            </Link>
          </div>
        </div>
        <div className="col-md-2 col-sm-12 my-5 my-md-auto">
          <img src={logo} alt="PICXQ" className="img-fluid footer-logo" />
          <address className="mb-5 mt-4 text-start">
            picxqinfotech@gmail.com
            <br />
            +918148036321
            <br />
            +918526334245
            <br />
          </address>

          <Social grey={false} footer />
        </div>
      </div>
      <hr className="text-muted" />
      <p className="text-center mt-5 text-muted">
        All rights reserved by PICXQ. &copy; {date.getFullYear()}
      </p>
    </div>
  );
};

export default Footer;
