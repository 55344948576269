import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { portfolioList } from "../data";
import GenericModal from "./GenericModal";

const Portfolio = () => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState();
  const navigate = useNavigate();

  const toggle = () => setOpen(!open);

  return (
    <div className="portfolio bg-light py-4 text-center">
      <p className="title">Portfolio</p>
      <div className="row mx-auto mt-4">
        {portfolioList.slice(0, 8).map((r, i) => (
          <img
            key={i}
            src={r.image}
            alt="qwerty"
            className="col-sm-12 col-md-3 img-fluid p-1 shadow"
            style={{ cursor: "pointer" }}
            onClick={() => (toggle(), setImage(r.image))}
          />
        ))}
        <GenericModal
          open={open}
          toggle={toggle}
          portfolio={true}
          pimg={image}
        />
      </div>
      <button
        className="btn-sm btn-custom-primary my-4 shadow"
        onClick={() => navigate("/company/portfolio")}
      >
        View More
      </button>
    </div>
  );
};

export default Portfolio;
